@import url('../../node_modules/reset-css/reset.css');
@import url('./extra-reset.css');

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EFF3F5;
  box-sizing: border-box;
}

.DayPickerInput > input {
  background-color: transparent;
  font-size: 14px;
  padding: 7.5px;
  max-width: 100px;
  text-align: center;
}

.DayPicker-Day--hoverRange {
  background-color: #EFEFEF !important;
}

.DayPicker-Day--selectedRange {
  background-color: #2684FF !important;
}

.recharts-tooltip-wrapper {
  z-index: 100;
}
